html {
  height: 100%;
  font-size: calc(62.5% + 0.25vw);
}
body {
  height: inherit;
}
#__next {
  height: inherit;
  overflow-y: auto;
}
